import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// import {AngularFireModule} from '@angular/fire';
// import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import { AgmCoreModule } from '@agm/core';

import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxSpinnerModule} from 'ngx-spinner';
import {RouterModule} from '@angular/router';
import {InterceptorService} from './shared/interceptor/interceptor.service';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';
import {AppComponent} from './app.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';
import {FullLayoutComponent} from './layouts/full/full-layout.component';
import {AuthService} from './shared/auth/auth.service';
import {AuthGuard} from './shared/auth/auth-guard.service';
import {WINDOW_PROVIDERS} from './shared/services/window.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {
  ScheduleModule, RecurrenceEditorModule, DayService, WeekService, WorkWeekService, MonthService, MonthAgendaService
} from '@syncfusion/ej2-angular-schedule';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFirestore} from '@angular/fire/firestore';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {ToastrModule} from 'ngx-toastr';


const firebaseConfig = {
  apiKey: 'YOUR_API_KEY', // YOUR_API_KEY
  authDomain: 'YOUR_AUTH_DOMAIN', // YOUR_AUTH_DOMAIN
  databaseURL: 'YOUR_DATABASE_URL', // YOUR_DATABASE_URL
  projectId: 'YOUR_PROJECT_ID', // YOUR_PROJECT_ID
  storageBucket: 'YOUR_STORAGE_BUCKET', // YOUR_STORAGE_BUCKET
  messagingSenderId: 'YOUR_MESSAGING_SENDER_ID', // YOUR_MESSAGING_SENDER_ID
  appId: 'YOUR_APP_ID', // YOUR_APP_ID
  measurementId: 'YOUR_MEASUREMENT_ID' // YOUR_MEASUREMENT_ID
};


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBMlKdxvIUzPgJCZuRjllZwYufs94BeHLc'
    }),
    AppRoutingModule,
    ToastrModule.forRoot(),
    CalendarModule,
    SharedModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    NgbModule, RouterModule, TranslateModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    PerfectScrollbarModule,
    NgSelectModule,
    ScheduleModule,
    RecurrenceEditorModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    AuthService, AngularFirestore,
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    MonthAgendaService,
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    WINDOW_PROVIDERS,
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true}
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
